/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
import DoctorPageTemplate from "../../templates/doctor-page";
import InfoBox from "../../components/info-box";
import PortraitSchoettes from "../../components/portraits/portrait-schoettes";
const MDXLayout = ({children}) => React.createElement(DoctorPageTemplate, {
  title: "Dr. med. Sebastian Schöttes",
  subline: "Ärztlicher Psychotherapeut",
  portrait: React.createElement(PortraitSchoettes)
}, children);
function _createMdxContent(props) {
  return React.createElement(React.Fragment, null, React.createElement(InfoBox, {
    title: "Facharzt für Neurologie"
  }), "\n", React.createElement(InfoBox, {
    title: "Facharzt für Psychiatrie und Psychotherapie"
  }), "\n", React.createElement(InfoBox, {
    title: "Fachkunde Suchtmedizinische Grundversorgung"
  }), "\n", React.createElement(InfoBox, {
    title: "Fachkunde Verkehrsmedizin"
  }), "\n", React.createElement(InfoBox, {
    title: "Fachkunde im Strahlenschutz"
  }), "\n", React.createElement(InfoBox, {
    title: "Psychosomatische Grundversorgung"
  }), "\n", React.createElement(InfoBox, {
    title: "Tiefenpsychologisch fundierte Psychotherapie (Einzelbehandlung und Gruppen)"
  }), "\n", React.createElement(InfoBox, {
    title: "EMDR-Therapeut (EMDRIA)"
  }), "\n", React.createElement(InfoBox, {
    title: "Balintgruppenleiter (Deutsche Balintgesellschaft)"
  }), "\n", React.createElement(InfoBox, {
    title: "Psychoonkologie"
  }));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
