import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const PortraitSchoettes = () => {
  const {
    portrait: {
      childImageSharp: { gatsbyImageData },
    },
  } = useStaticQuery(query);

  return (
    <GatsbyImage
      image={gatsbyImageData}
      alt="Portrait Dr. Schöttes"
      className="h-full"
    />
  );
};

const query = graphql`
  query ProtraitSchoettesQuery {
    portrait: file(relativePath: { eq: "Herr-Dr-Schoettes.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
  }
`;

export default PortraitSchoettes;
